.NewsItem {
    padding: 40px 0;

    h6 {
        color: var(--fg-re-blue) !important;
        font-family: 'main-text-regular', sans-serif !important;
        font-size: 14px;
    }

    .img-col {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        img {
            width: 80%;
            height: auto;
            border-radius: 50%;
            border: 4px solid #fff;
            //border: 4px solid var(--bg-re-blue);
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        .hide-small {
            display: none;
        }

        .show-small {
            display: block;
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
