.Button {
    padding: 10px 20px;
    text-align: center;
    color: var(--fg-white);
    display: inline-block;
    font-size: 16px;
    transition: var(--transition);
    cursor: pointer;
    border-radius: 25px;
    font-family: 'main-font-regular', sans-serif;
    text-transform: lowercase;
    white-space: nowrap;

    &[data-object-type='blue'] {
        background-color: var(--bg-re-blue);
        &:hover {
            background-color: var(--bg-re-blue-dark-20);
            //box-shadow: 0 0 10px 0  var(--bg-re-blue-dark-20);
        }
    }

    &[data-object-type='purple'] {
        background-color: var(--bg-re-purple);
        &:hover {
            background-color: var(--bg-re-purple-dark-15);
        }
    }

    // XS - Phone;
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
