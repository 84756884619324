html {
    overflow-x: clip;
    overflow-y: visible;
    background-color: var(--bg- + map-get($page, 'backgroundColor'));
}

body {
    margin: map-get($page, 'margin');
    padding: map-get($page, 'padding');
    background-color: var(--bg- + map-get($page, 'backgroundColor'));
    font-family: 'main-text-regular', sans-serif;
    overflow-x: hidden !important;
}

textarea:focus,
input:focus {
    outline: none;
}
