.ScrollIndicator {
    position: fixed;
    top: 0;
    width: 100%;
    height: 4px;
    background: transparent;
    z-index: 52000;

    .scrollIndicator {
        height: 4px;
        background: linear-gradient(to right, var(--bg-re-blue), var(--bg-re-purple));
        width: 0;
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
