.Hero {
    z-index: 400000;

    .content {
        height: 100vh;
        display: flex;
        align-items: center;
    }

    h1 {
        font-size: 52px;
    }

    // XS - Phone
    @media (max-width: 480px) {
        .Logo {
            display: none;
        }
        h1 {
            font-size: 36px !important;
        }
    }

    // S - Tablet
    @media (max-width: 768px) {
        .content {
            padding: 40px;
        }
        h1 {
            font-size: 42px;
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1080px) {
        h1 {
            font-size: 42px;
        }
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
