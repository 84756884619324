@use 'sass:math';

.Menu {
    $height: 80px;

    position: fixed;
    z-index: 50000;
    top: 0;
    left: 0;
    right: 0;
    height: $height;
    background-color: var(--bg-transparent-black-60);
    padding: math.div($height, 5);
    align-items: center;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.3px);
    -webkit-backdrop-filter: blur(9.3px);

    .logo-block {
        height: math.div($height, 2);
        display: flex;
        align-items: center;
    }
    .menu-block {
        display: flex !important;
        align-items: center;
        gap: $height;
        justify-content: flex-start;
        height: math.div($height, 2);
    }

    .language-selector {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: math.div($height, 2);
        gap: 10px;
        color: var(--fg-white);
        font-size: 14px;

        .active {
            color: var(--fg-re-blue);
        }

        span {
            cursor: pointer;

            &:hover {
                color: var(--fg-re-blue);
            }
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        .menu-block {
            display: none !important;
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
