.NewsPage {
    margin-top: 10%;

    .content-item {
        margin: 5% 0;
        opacity: 1 !important;
    }

    h6 {
        color: var(--fg-re-blue) !important;
        font-family: 'main-text-regular', sans-serif !important;
        font-size: 14px;
    }

    .img-col {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 80%;
            height: auto;
            border-radius: 50%;
            border: 4px solid var(--bg-white);
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        margin-top: 10%;
        padding: 5%;

        .img-col {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            margin-top: 60px;

            img {
                width: 60%;
                height: auto;
                border-radius: 50%;
                //border: 10px solid var(--bg-white);
            }
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
