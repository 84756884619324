@each $variant in map-get($headings, 'variant') {
    @each $t, $s in $variant {
        #{$t} {
            font-family: 'heading';
            font-size: pxToRem($s);
            font-weight: normal;
            color: var(--fg-white) !important;
            margin-bottom: 8px;
        }
    }
}
