.Footer {
    padding-top: 5%;
    .about-box {
        //padding: 40px;
        color: var(--fg-white-muted);
        //opacity: 0.3 !important;
        cursor: pointer;
        font-family: 'main-text-regular', sans-serif !important;
        font-weight: normal !important;

        span {
            //font-family: 'main-text-regular', sans-serif !important;
            //font-weight: normal !important;
            color: var(--fg-white-muted) !important;
        }

        img {
            height: 40px;
            margin: 10px 0;
            opacity: 1 !important;
        }

        &:hover {
            opacity: 1 !important;
            text-decoration: none;
        }
    }

    .purple-line {
        border-bottom: 4px solid var(--bg-re-purple);
    }

    .blue-line {
        border-bottom: 4px solid var(--bg-re-blue);
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
