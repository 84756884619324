.Section {
    padding: 80px 0;

    .item-image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10%;
    }

    .item-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .more-content {
        margin: 20px 0;
        transition: var(--transition);
        display: none;
    }

    .show-small {
        display: none;
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        padding: 20px 0;
        padding-top: 40px;

        .item-content {
            padding: 40px;
        }

        .item-image {
            padding: 0 80px;
        }

        .hide-small {
            display: none;
        }

        .show-small {
            display: block;
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
