.ContactForm {
    //background: linear-gradient(to bottom right, var(--bg-re-blue), var(--bg-re-purple));
    padding: 4% 0;
    transition: all 0.4s ease;
    margin: 0 2% 10%;
    border-radius: 25px;

    h1 {
        text-align: center;
        font-size: 72px;
        //background: -webkit-linear-gradient(to right, #eee, #333);
        background: linear-gradient(to bottom right, var(--bg-re-blue), var(--bg-re-purple));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 4rem 0;
    }

    .float-right {
        display: flex;
        align-items: flex-end;
    }

    .float-left {
        display: flex;
        align-items: flex-start;
    }

    .contact-image {
        width: 40px;
    }

    .error {
        color: #f00 !important;
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        padding: 4% 4%;
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
