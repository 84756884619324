.Paragraph {
    // font-size: 1.50rem;
    color: var(--fg-white-muted);

    //min-height: 20vh;

    &[data-object-type='small'] p {
        font-size: 1.2rem !important;
    }

    &[data-object-type='default'] p {
        font-size: 1.5rem !important;
        line-height: 2.2rem;
        opacity: 0.7;
    }

    &[data-object-type='intro'] p {
        font-size: 1.5rem !important;
        line-height: 2.2rem;
        opacity: 0.7;
        font-style: italic !important;
    }

    &[data-object-type='hero'] p {
        font-size: 2rem !important;
    }

    //margin-top: 10px;

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        &[data-object-type='hero'] p {
            font-size: 1.3rem !important;
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1080px) {
        &[data-object-type='hero'] p {
            font-size: 1.6rem !important;
        }
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
