/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
a {
    text-decoration: none;
}

/** FONTS **/
/*! main-text-regular */
@font-face {
    font-family: 'main-text-regular';
    src: url('../fonts/bariol.woff') format('woff'), url('../fonts/bariol.woff2') format('woff2');
}
/*! main-text-bold */
@font-face {
    font-family: 'main-text-bold';
    src: url('../fonts/bariol-bold.woff') format('woff'), url('../fonts/bariol-bold.woff2') format('woff2');
}
/*! main-text-link */
@font-face {
    font-family: 'main-text-link';
    src: url('../fonts/bariol-bold.woff') format('woff'), url('../fonts/bariol-bold.woff2') format('woff2');
}
/*! label-text */
@font-face {
    font-family: 'label-text';
    src: url('../fonts/bariol.woff') format('woff'), url('../fonts/bariol.woff2') format('woff2');
}
/*! heading */
@font-face {
    font-family: 'heading';
    src: url('../fonts/bariol-bold.woff') format('woff'), url('../fonts/bariol-bold.woff2') format('woff2');
}
/** CUSTOM PROPERTIES **/
:root {
    /** COLOR **/
    /*! Background Color */
    /*! black */
    --bg-black: #000;
    /*! highlight-black */
    --bg-highlight-black: #12100e;
    /*! transparent-black-60 */
    --bg-transparent-black-60: rgba(18, 16, 14, 0.6);
    /*! transparent-black-80 */
    --bg-transparent-black-80: rgba(18, 16, 14, 0.8);
    /*! white */
    --bg-white: #fff;
    /*! white-muted */
    --bg-white-muted: rgba(255, 255, 255, 0.8);
    /*! re-blue */
    --bg-re-blue: #58aae1;
    --bg-re-blue-light-10: rgb(131.2335025381, 192.0050761421, 232.7664974619);
    --bg-re-blue-light-15: rgb(152.8502538071, 203.0076142132, 236.6497461929);
    --bg-re-blue-light-20: rgb(174.4670050761, 214.0101522843, 240.5329949239);
    --bg-re-blue-light-30: rgb(217.7005076142, 236.0152284264, 248.2994923858);
    --bg-re-blue-dark-10: rgb(44.7664974619, 147.9949238579, 217.2335025381);
    --bg-re-blue-dark-15: rgb(36.0152284264, 134.4568527919, 200.4847715736);
    --bg-re-blue-dark-20: rgb(32.1319796954, 119.9593908629, 178.8680203046);
    --bg-re-blue-dark-30: rgb(24.3654822335, 90.9644670051, 135.6345177665);
    /*! re-purple */
    --bg-re-purple: #7f4690;
    --bg-re-purple-light-10: rgb(155.3971962617, 90.1401869159, 174.8598130841);
    --bg-re-purple-light-15: rgb(165.7640186916, 107.2990654206, 183.2009345794);
    --bg-re-purple-light-20: rgb(176.1308411215, 124.4579439252, 191.5420560748);
    --bg-re-purple-light-30: rgb(196.8644859813, 158.7757009346, 208.2242990654);
    --bg-re-purple-dark-10: rgb(96.7336448598, 53.3177570093, 109.6822429907);
    --bg-re-purple-dark-15: rgb(81.6004672897, 44.976635514, 92.523364486);
    --bg-re-purple-dark-20: rgb(66.4672897196, 36.6355140187, 75.3644859813);
    --bg-re-purple-dark-30: rgb(36.2009345794, 19.953271028, 41.046728972);
    /*! Foreground Color */
    /*! black */
    --fg-black: #000;
    /*! highlight-black */
    --fg-highlight-black: #12100e;
    /*! transparent-black-60 */
    --fg-transparent-black-60: rgba(18, 16, 14, 0.6);
    /*! transparent-black-80 */
    --fg-transparent-black-80: rgba(18, 16, 14, 0.8);
    /*! white */
    --fg-white: #fff;
    /*! white-muted */
    --fg-white-muted: rgba(255, 255, 255, 0.8);
    /*! re-blue */
    --fg-re-blue: #58aae1;
    --fg-re-blue-light-10: rgb(131.2335025381, 192.0050761421, 232.7664974619);
    --fg-re-blue-light-15: rgb(152.8502538071, 203.0076142132, 236.6497461929);
    --fg-re-blue-light-20: rgb(174.4670050761, 214.0101522843, 240.5329949239);
    --fg-re-blue-light-30: rgb(217.7005076142, 236.0152284264, 248.2994923858);
    --fg-re-blue-dark-10: rgb(44.7664974619, 147.9949238579, 217.2335025381);
    --fg-re-blue-dark-15: rgb(36.0152284264, 134.4568527919, 200.4847715736);
    --fg-re-blue-dark-20: rgb(32.1319796954, 119.9593908629, 178.8680203046);
    --fg-re-blue-dark-30: rgb(24.3654822335, 90.9644670051, 135.6345177665);
    /*! re-purple */
    --fg-re-purple: #7f4690;
    --fg-re-purple-light-10: rgb(155.3971962617, 90.1401869159, 174.8598130841);
    --fg-re-purple-light-15: rgb(165.7640186916, 107.2990654206, 183.2009345794);
    --fg-re-purple-light-20: rgb(176.1308411215, 124.4579439252, 191.5420560748);
    --fg-re-purple-light-30: rgb(196.8644859813, 158.7757009346, 208.2242990654);
    --fg-re-purple-dark-10: rgb(96.7336448598, 53.3177570093, 109.6822429907);
    --fg-re-purple-dark-15: rgb(81.6004672897, 44.976635514, 92.523364486);
    --fg-re-purple-dark-20: rgb(66.4672897196, 36.6355140187, 75.3644859813);
    --fg-re-purple-dark-30: rgb(36.2009345794, 19.953271028, 41.046728972);
    /** FONT **/
    --main-text-regular: 'main-text-regular';
    --main-text-bold: 'main-text-bold';
    --main-text-link: 'main-text-link';
    --label-text: 'label-text';
    --transition: all 0.4s ease;
}

html {
    overflow-x: clip;
    overflow-y: visible;
    background-color: var(--bg-highlight-black);
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--bg-highlight-black);
    font-family: 'main-text-regular', sans-serif;
    overflow-x: hidden !important;
}

textarea:focus,
input:focus {
    outline: none;
}

/** UTILITY: Colors **/
/*! Background Color */
/*! black */
.bg-black {
    background-color: var(--bg-black);
}

/*! highlight-black */
.bg-highlight-black {
    background-color: var(--bg-highlight-black);
}

/*! transparent-black-60 */
.bg-transparent-black-60 {
    background-color: var(--bg-transparent-black-60);
}

/*! transparent-black-80 */
.bg-transparent-black-80 {
    background-color: var(--bg-transparent-black-80);
}

/*! white */
.bg-white {
    background-color: var(--bg-white);
}

/*! white-muted */
.bg-white-muted {
    background-color: var(--bg-white-muted);
}

/*! re-blue */
.bg-re-blue {
    background-color: var(--bg-re-blue);
}

.bg-re-blue-light-10 {
    background-color: var(--bg-re-blue-light-10);
}

.bg-re-blue-light-15 {
    background-color: var(--bg-re-blue-light-15);
}

.bg-re-blue-light-20 {
    background-color: var(--bg-re-blue-light-20);
}

.bg-re-blue-light-30 {
    background-color: var(--bg-re-blue-light-30);
}

.bg-re-blue-dark-10 {
    background-color: var(--bg-re-blue-dark-10);
}

.bg-re-blue-dark-15 {
    background-color: var(--bg-re-blue-dark-15);
}

.bg-re-blue-dark-20 {
    background-color: var(--bg-re-blue-dark-20);
}

.bg-re-blue-dark-30 {
    background-color: var(--bg-re-blue-dark-30);
}

/*! re-purple */
.bg-re-purple {
    background-color: var(--bg-re-purple);
}

.bg-re-purple-light-10 {
    background-color: var(--bg-re-purple-light-10);
}

.bg-re-purple-light-15 {
    background-color: var(--bg-re-purple-light-15);
}

.bg-re-purple-light-20 {
    background-color: var(--bg-re-purple-light-20);
}

.bg-re-purple-light-30 {
    background-color: var(--bg-re-purple-light-30);
}

.bg-re-purple-dark-10 {
    background-color: var(--bg-re-purple-dark-10);
}

.bg-re-purple-dark-15 {
    background-color: var(--bg-re-purple-dark-15);
}

.bg-re-purple-dark-20 {
    background-color: var(--bg-re-purple-dark-20);
}

.bg-re-purple-dark-30 {
    background-color: var(--bg-re-purple-dark-30);
}

/*! Foreground Color */
/*! black */
.fg-black {
    color: var(--fg-black);
}

/*! highlight-black */
.fg-highlight-black {
    color: var(--fg-highlight-black);
}

/*! transparent-black-60 */
.fg-transparent-black-60 {
    color: var(--fg-transparent-black-60);
}

/*! transparent-black-80 */
.fg-transparent-black-80 {
    color: var(--fg-transparent-black-80);
}

/*! white */
.fg-white {
    color: var(--fg-white);
}

/*! white-muted */
.fg-white-muted {
    color: var(--fg-white-muted);
}

/*! re-blue */
.fg-re-blue {
    color: var(--fg-re-blue);
}

.fg-re-blue-light-10 {
    color: var(--fg-re-blue-light-10);
}

.fg-re-blue-light-15 {
    color: var(--fg-re-blue-light-15);
}

.fg-re-blue-light-20 {
    color: var(--fg-re-blue-light-20);
}

.fg-re-blue-light-30 {
    color: var(--fg-re-blue-light-30);
}

.fg-re-blue-dark-10 {
    color: var(--fg-re-blue-dark-10);
}

.fg-re-blue-dark-15 {
    color: var(--fg-re-blue-dark-15);
}

.fg-re-blue-dark-20 {
    color: var(--fg-re-blue-dark-20);
}

.fg-re-blue-dark-30 {
    color: var(--fg-re-blue-dark-30);
}

/*! re-purple */
.fg-re-purple {
    color: var(--fg-re-purple);
}

.fg-re-purple-light-10 {
    color: var(--fg-re-purple-light-10);
}

.fg-re-purple-light-15 {
    color: var(--fg-re-purple-light-15);
}

.fg-re-purple-light-20 {
    color: var(--fg-re-purple-light-20);
}

.fg-re-purple-light-30 {
    color: var(--fg-re-purple-light-30);
}

.fg-re-purple-dark-10 {
    color: var(--fg-re-purple-dark-10);
}

.fg-re-purple-dark-15 {
    color: var(--fg-re-purple-dark-15);
}

.fg-re-purple-dark-20 {
    color: var(--fg-re-purple-dark-20);
}

.fg-re-purple-dark-30 {
    color: var(--fg-re-purple-dark-30);
}

.main-text-regular-12 {
    font-family: 'main-text-regular';
    font-size: 0.75rem;
}

.main-text-regular-14 {
    font-family: 'main-text-regular';
    font-size: 0.875rem;
}

.main-text-regular-16 {
    font-family: 'main-text-regular';
    font-size: 1rem;
}

.main-text-regular-18 {
    font-family: 'main-text-regular';
    font-size: 1.125rem;
}

.main-text-regular-20 {
    font-family: 'main-text-regular';
    font-size: 1.25rem;
}

.main-text-bold-12 {
    font-family: 'main-text-bold';
    font-size: 0.75rem;
}

.main-text-bold-14 {
    font-family: 'main-text-bold';
    font-size: 0.875rem;
}

.main-text-bold-16 {
    font-family: 'main-text-bold';
    font-size: 1rem;
}

.main-text-bold-18 {
    font-family: 'main-text-bold';
    font-size: 1.125rem;
}

.main-text-bold-20 {
    font-family: 'main-text-bold';
    font-size: 1.25rem;
}

.main-text-link-12 {
    font-family: 'main-text-link';
    font-size: 0.75rem;
}

.main-text-link-14 {
    font-family: 'main-text-link';
    font-size: 0.875rem;
}

.main-text-link-16 {
    font-family: 'main-text-link';
    font-size: 1rem;
}

.label-text-12 {
    font-family: 'label-text';
    font-size: 0.75rem;
}

.label-text-14 {
    font-family: 'label-text';
    font-size: 0.875rem;
}

.label-text-16 {
    font-family: 'label-text';
    font-size: 1rem;
}

.label-text-18 {
    font-family: 'label-text';
    font-size: 1.125rem;
}

.label-text-20 {
    font-family: 'label-text';
    font-size: 1.25rem;
}

.label-text-22 {
    font-family: 'label-text';
    font-size: 1.375rem;
}

.label-text-24 {
    font-family: 'label-text';
    font-size: 1.5rem;
}

.label-text-26 {
    font-family: 'label-text';
    font-size: 1.625rem;
}

.label-text-28 {
    font-family: 'label-text';
    font-size: 1.75rem;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.is-flex {
    display: flex;
}

.is-row {
    flex-direction: row;
}

.is-column {
    flex-direction: column;
}

.float-right {
    justify-content: flex-end;
}

.float-start {
    justify-content: flex-start;
}

.float-center {
    justify-content: center;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

a,
p a {
    color: var(--fg-re-blue);
    transition: all 0.4s ease;
    font-family: 'main-text-bold', sans-serif;
}
a:hover,
p a:hover {
    color: var(--fg-re-blue-dark-15);
    text-decoration: underline;
}

h1 {
    font-family: 'heading';
    font-size: 3rem;
    font-weight: normal;
    color: var(--fg-white) !important;
    margin-bottom: 8px;
}

h2 {
    font-family: 'heading';
    font-size: 2.375rem;
    font-weight: normal;
    color: var(--fg-white) !important;
    margin-bottom: 8px;
}

h3 {
    font-family: 'heading';
    font-size: 1.75rem;
    font-weight: normal;
    color: var(--fg-white) !important;
    margin-bottom: 8px;
}

h4 {
    font-family: 'heading';
    font-size: 1.625rem;
    font-weight: normal;
    color: var(--fg-white) !important;
    margin-bottom: 8px;
}

h5 {
    font-family: 'heading';
    font-size: 1.5rem;
    font-weight: normal;
    color: var(--fg-white) !important;
    margin-bottom: 8px;
}

h6 {
    font-family: 'heading';
    font-size: 1.375rem;
    font-weight: normal;
    color: var(--fg-white) !important;
    margin-bottom: 8px;
}

.Button {
    padding: 10px 20px;
    text-align: center;
    color: var(--fg-white);
    display: inline-block;
    font-size: 16px;
    transition: var(--transition);
    cursor: pointer;
    border-radius: 25px;
    font-family: 'main-font-regular', sans-serif;
    text-transform: lowercase;
    white-space: nowrap;
}
.Button[data-object-type='blue'] {
    background-color: var(--bg-re-blue);
}
.Button[data-object-type='blue']:hover {
    background-color: var(--bg-re-blue-dark-20);
}
.Button[data-object-type='purple'] {
    background-color: var(--bg-re-purple);
}
.Button[data-object-type='purple']:hover {
    background-color: var(--bg-re-purple-dark-15);
}
.Logo {
    cursor: pointer !important;
}
.Logo img {
    cursor: pointer !important;
}
.Logo.xs img {
    height: 20px;
}
.Logo.s img {
    height: 40px;
}
.Logo.m img {
    height: 60px;
}
.Logo.l img {
    height: 80px;
}
.Logo.xl img {
    height: 100px;
}
.Logo.xxl img {
    height: 120px;
}
@media (max-width: 480px) {
    .Logo img {
        height: 20px !important;
    }
}
.MenuItem {
    text-transform: lowercase;
    font-size: 22px;
}
.MenuItem a {
    color: var(--fg-white);
    font-weight: normal !important;
    font-family: 'main-text-regular', sans-serif;
}
.MenuItem a:hover {
    color: var(--fg-re-blue);
    text-decoration: none;
}
.Heading {
    color: var(--fg-white);
    font-family: 'heading', sans-serif;
    font-weight: normal;
    text-transform: lowercase;
}
.Paragraph {
    color: var(--fg-white-muted);
}
.Paragraph[data-object-type='small'] p {
    font-size: 1.2rem !important;
}
.Paragraph[data-object-type='default'] p {
    font-size: 1.5rem !important;
    line-height: 2.2rem;
    opacity: 0.7;
}
.Paragraph[data-object-type='intro'] p {
    font-size: 1.5rem !important;
    line-height: 2.2rem;
    opacity: 0.7;
    font-style: italic !important;
}
.Paragraph[data-object-type='hero'] p {
    font-size: 2rem !important;
}
@media (max-width: 768px) {
    .Paragraph[data-object-type='hero'] p {
        font-size: 1.3rem !important;
    }
}
@media (max-width: 1080px) {
    .Paragraph[data-object-type='hero'] p {
        font-size: 1.6rem !important;
    }
}
.ScrollIndicator {
    position: fixed;
    top: 0;
    width: 100%;
    height: 4px;
    background: transparent;
    z-index: 52000;
}
.ScrollIndicator .scrollIndicator {
    height: 4px;
    background: linear-gradient(to right, var(--bg-re-blue), var(--bg-re-purple));
    width: 0;
}
.InputField {
    width: 100%;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    background-color: transparent;
    border-bottom: 1px solid var(--fg-re-blue);
    color: var(--fg-white);
    font-size: 2rem;
}
.InputField:focus {
    border-bottom: 1px solid var(--fg-re-purple);
}
@media (max-width: 480px) {
    .InputField {
        font-size: 1.6rem;
    }
}
.TextArea {
    width: 100%;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    background-color: transparent;
    border-bottom: 1px solid var(--fg-re-blue);
    color: var(--fg-white);
    font-size: 2rem;
    transition: var(--transition);
}
.TextArea TextArea::placeholder {
    font-size: 2rem;
}
.TextArea:focus {
    border-bottom: 1px solid var(--fg-re-purple);
}
@media (max-width: 480px) {
    .TextArea {
        font-size: 1.6rem;
    }
}
.ThankYou h2 {
    text-align: center;
    margin-bottom: 40px;
}
.ThankYou p {
    text-align: center;
    font-size: 24px;
    color: var(--fg-white);
}
.ThankYou h1 {
    text-align: center;
    font-size: 62px !important;
    background: linear-gradient(to bottom right, var(--bg-re-blue), var(--bg-re-purple));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 4rem 0;
}
@media (max-width: 480px) {
    .ThankYou h1 {
        font-size: 32px !important;
    }
}
.YouTubeEmbed {
    padding: 40px 0;
}
.YouTubeEmbed iframe {
    height: 100%;
    width: 100%;
    min-height: 400px;
}
.Menu {
    position: fixed;
    z-index: 50000;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: var(--bg-transparent-black-60);
    padding: 16px;
    align-items: center;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.3px);
    -webkit-backdrop-filter: blur(9.3px);
}
.Menu .logo-block {
    height: 40px;
    display: flex;
    align-items: center;
}
.Menu .menu-block {
    display: flex !important;
    align-items: center;
    gap: 80px;
    justify-content: flex-start;
    height: 40px;
}
.Menu .language-selector {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    gap: 10px;
    color: var(--fg-white);
    font-size: 14px;
}
.Menu .language-selector .active {
    color: var(--fg-re-blue);
}
.Menu .language-selector span {
    cursor: pointer;
}
.Menu .language-selector span:hover {
    color: var(--fg-re-blue);
}
@media (max-width: 768px) {
    .Menu .menu-block {
        display: none !important;
    }
}
.Section {
    padding: 80px 0;
}
.Section .item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10%;
}
.Section .item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.Section .more-content {
    margin: 20px 0;
    transition: var(--transition);
    display: none;
}
.Section .show-small {
    display: none;
}
@media (max-width: 768px) {
    .Section {
        padding: 20px 0;
        padding-top: 40px;
    }
    .Section .item-content {
        padding: 40px;
    }
    .Section .item-image {
        padding: 0 80px;
    }
    .Section .hide-small {
        display: none;
    }
    .Section .show-small {
        display: block;
    }
}
.ButtonBar {
    display: flex;
    gap: 20px;
    margin: 5% 0;
}
.Waves {
    opacity: 0.4;
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -100;
    /*Shrinking for mobile*/
}
.Waves .waves {
    position: absolute;
    width: 100%;
    height: 15vh;
    min-height: 200px;
    max-height: 250px;
    bottom: 45%;
    left: 0;
}
.Waves .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.Waves .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 5s;
}
.Waves .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}
.Waves .parallax > use:nth-child(3) {
    animation-delay: -8s;
    animation-duration: 15s;
}
.Waves .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}
@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}
@media (max-width: 768px) {
    .Waves .waves {
        height: 140px;
        min-height: 140px;
    }
}
.Parallax .Banner {
    height: 95vh;
    margin-bottom: 5vh;
    margin-top: 5vh;
}
.Parallax .show-on-small {
    display: none;
}
@media (max-width: 480px) {
    .Parallax .show-on-small {
        display: block;
    }
    .Parallax .show-on-small img {
        margin-top: 5vh;
    }
    .Parallax .show-on-small .mobile-hero {
        margin-top: -160px !important;
        margin-bottom: -100px;
    }
    .Parallax .hide-for-small {
        display: none;
    }
    .Parallax .Banner {
        margin-bottom: 5vh;
    }
}
@media (max-width: 768px) {
    .Parallax .hide-for-small {
        display: none;
    }
    .Parallax .hide-for-small img {
        margin-top: 5vh;
    }
    .Parallax .show-on-small {
        display: block;
    }
    .Parallax .show-on-small .mobile-hero {
        margin-top: -180px;
    }
}
.NewsItem {
    padding: 40px 0;
}
.NewsItem h6 {
    color: var(--fg-re-blue) !important;
    font-family: 'main-text-regular', sans-serif !important;
    font-size: 14px;
}
.NewsItem .img-col {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.NewsItem .img-col img {
    width: 80%;
    height: auto;
    border-radius: 50%;
    border: 4px solid #fff;
}
@media (max-width: 768px) {
    .NewsItem .hide-small {
        display: none;
    }
    .NewsItem .show-small {
        display: block;
    }
}
.Footer {
    padding-top: 5%;
}
.Footer .about-box {
    color: var(--fg-white-muted);
    cursor: pointer;
    font-family: 'main-text-regular', sans-serif !important;
    font-weight: normal !important;
}
.Footer .about-box span {
    color: var(--fg-white-muted) !important;
}
.Footer .about-box img {
    height: 40px;
    margin: 10px 0;
    opacity: 1 !important;
}
.Footer .about-box:hover {
    opacity: 1 !important;
    text-decoration: none;
}
.Footer .purple-line {
    border-bottom: 4px solid var(--bg-re-purple);
}
.Footer .blue-line {
    border-bottom: 4px solid var(--bg-re-blue);
}
.Hero {
    z-index: 400000;
}
.Hero .content {
    height: 100vh;
    display: flex;
    align-items: center;
}
.Hero h1 {
    font-size: 52px;
}
@media (max-width: 480px) {
    .Hero .Logo {
        display: none;
    }
    .Hero h1 {
        font-size: 36px !important;
    }
}
@media (max-width: 768px) {
    .Hero .content {
        padding: 40px;
    }
    .Hero h1 {
        font-size: 42px;
    }
}
@media (max-width: 1080px) {
    .Hero h1 {
        font-size: 42px;
    }
}
.ContactForm {
    padding: 4% 0;
    transition: all 0.4s ease;
    margin: 0 2% 10%;
    border-radius: 25px;
}
.ContactForm h1 {
    text-align: center;
    font-size: 72px;
    background: linear-gradient(to bottom right, var(--bg-re-blue), var(--bg-re-purple));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 4rem 0;
}
.ContactForm .float-right {
    display: flex;
    align-items: flex-end;
}
.ContactForm .float-left {
    display: flex;
    align-items: flex-start;
}
.ContactForm .contact-image {
    width: 40px;
}
.ContactForm .error {
    color: #f00 !important;
}
@media (max-width: 768px) {
    .ContactForm {
        padding: 4% 4%;
    }
}
.NewsList {
    margin: 10% 0;
}
.NewsList h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--bg-re-blue);
}
@media (max-width: 768px) {
    .NewsList {
        margin: 8% 5%;
    }
}
.Main {
    margin: 0 !important;
}
.Wait {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10%;
}
.Wait img {
    height: 60vh;
}
.NotFound {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10%;
}
.NotFound img {
    height: 60vh;
}
.NewsPage {
    margin-top: 10%;
}
.NewsPage .content-item {
    margin: 5% 0;
    opacity: 1 !important;
}
.NewsPage h6 {
    color: var(--fg-re-blue) !important;
    font-family: 'main-text-regular', sans-serif !important;
    font-size: 14px;
}
.NewsPage .img-col {
    display: flex;
    align-items: center;
    justify-content: center;
}
.NewsPage .img-col img {
    width: 80%;
    height: auto;
    border-radius: 50%;
    border: 4px solid var(--bg-white);
}
@media (max-width: 768px) {
    .NewsPage {
        margin-top: 10%;
        padding: 5%;
    }
    .NewsPage .img-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 60px;
    }
    .NewsPage .img-col img {
        width: 60%;
        height: auto;
        border-radius: 50%;
    }
}

