.Logo {
    $base: 20px;
    cursor: pointer !important;

    img {
        cursor: pointer !important;
    }

    &.xs img {
        height: 1 * $base;
    }
    &.s img {
        height: 2 * $base;
    }
    &.m img {
        height: 3 * $base;
    }
    &.l img {
        height: 4 * $base;
    }
    &.xl img {
        height: 5 * $base;
    }
    &.xxl img {
        height: 6 * $base;
    }

    // XS - Phone
    @media (max-width: 480px) {
        img {
            height: 20px !important;
        }
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
