.Waves {
    opacity: 0.4;
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -100;

    .waves {
        position: absolute;
        width: 100%;
        height: 15vh;
        min-height: 200px;
        max-height: 250px;
        bottom: 45%;
        left: 0;
    }

    .parallax > use {
        animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    }

    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 5s;
    }

    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
    }

    .parallax > use:nth-child(3) {
        animation-delay: -8s;
        animation-duration: 15s;
    }

    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
    }

    @keyframes move-forever {
        0% {
            transform: translate3d(-90px, 0, 0);
        }

        100% {
            transform: translate3d(85px, 0, 0);
        }
    }

    /*Shrinking for mobile*/

    @media (max-width: 768px) {
        .waves {
            height: 140px;
            min-height: 140px;
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
        // display: none;
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
