.TextArea {
    width: 100%;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    background-color: transparent;
    border-bottom: 1px solid var(--fg-re-blue);
    color: var(--fg-white);
    font-size: 2rem;
    transition: var(--transition);

    TextArea::placeholder {
        font-size: 2rem;
    }

    &:focus {
        border-bottom: 1px solid var(--fg-re-purple);
    }

    // XS - Phone
    @media (max-width: 480px) {
        font-size: 1.6rem;
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
