.ThankYou {
    h2 {
        text-align: center;
        margin-bottom: 40px;
    }

    p {
        text-align: center;
        font-size: 24px;
        color: var(--fg-white);
    }

    h1 {
        text-align: center;
        font-size: 62px !important;
        //background: -webkit-linear-gradient(to right, #eee, #333);
        background: linear-gradient(to bottom right, var(--bg-re-blue), var(--bg-re-purple));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 4rem 0;
    }

    // XS - Phone
    @media (max-width: 480px) {
        h1 {
            font-size: 32px !important;
        }
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
