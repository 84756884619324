.Parallax {
    .Banner {
        height: 95vh;
        margin-bottom: 5vh;
        margin-top: 5vh;
    }

    .show-on-small {
        display: none;
    }

    // XS - Phone
    @media (max-width: 480px) {
        .show-on-small {
            display: block;
            img {
                margin-top: 5vh;
            }
            & .mobile-hero {
                margin-top: -160px !important;
                margin-bottom: -100px;
            }
        }
        .hide-for-small {
            display: none;
        }
        .Banner {
            margin-bottom: 5vh;
        }
    }

    // S - Tablet
    @media (max-width: 768px) {
        .hide-for-small {
            display: none;

            img {
                margin-top: 5vh;
            }
        }
        .show-on-small {
            display: block;

            & .mobile-hero {
                margin-top: -180px;
            }
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1080px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
        //.show-on-small {
        //  display: none;
        //}
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
        //.show-on-small {
        //  display: none;
        //}
    }
}
