.MenuItem {
    text-transform: lowercase;
    font-size: 22px;

    a {
        color: var(--fg-white);
        font-weight: normal !important;
        font-family: 'main-text-regular', sans-serif;

        &:hover {
            color: var(--fg-re-blue);
            text-decoration: none;
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
